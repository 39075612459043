/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import randomized from "randomatic";
import { useReactToPrint } from 'react-to-print';
import BoxLayout from "../BoxLayout/BoxLayout";
import request from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import BOX_TYPE from "../../../constant/BoxTypes";
import Legend from "../../../constant/Legend";
import BOX_STATUS from "../../../constant/BoxStatus";
import { BtnLoader, Loader } from "../../components/bootstrap/Loader";
import EventTypes from "../../../constant/EventTypes";
import CurdTypes from "../../../constant/CurdTypes";
import { AgGridReact } from "ag-grid-react";
import { ModuleRegistry } from '@ag-grid-community/core';
import 'ag-grid-enterprise';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import Select from 'react-select'
import { Auth } from "aws-amplify";
import { io } from 'socket.io-client';
import RetailLabelTemplate from "./PrintTemplate";

ModuleRegistry.registerModules([InfiniteRowModelModule, SetFilterModule, MenuModule, ColumnsToolPanelModule]);

let TransactionFilterObj = {
    count: 100,
    filterModel: {},
    page: 0,
    searchObject: '',
    sortColumn: "created_on",
    sortOrder: "desc",
    tableName: "orders"
};


const TransactionSortObj = {
    request_no: { tableName: 'orders', sortColumn: 'request_no' },
    first_name: { tableName: 'icustomers', sortColumn: 'first_name' },
    last_name: { tableName: 'icustomers', sortColumn: 'last_name' },
    email: { tableName: 'icustomers', sortColumn: 'email' },
    name: { tableName: 'ecom_product', sortColumn: 'name' },
    bin_id: { tableName: 'orders', sortColumn: 'bin_id' },
};



const AssignProduct = () => {
    const gridRef = useRef();

    const [loader, setLoader] = useState("");
    const [createInvoice, setCreateInvoice] = useState(true);
    const [binStatus, setBinStatus] = useState([]);
    const [deactivatedBins, setDeactivatedBins] = useState([]);
    const [selectedBinId, setSelectedBinId] = useState("");
    const [expansionUnits, setExpansionUnits] = useState([]);
    const [boxTypeId, setBoxTypeId] = useState("");
    const [boxPosition, setBoxPosition] = useState("");
    const [locationTypeId, setLocationTypeId] = useState("");
    const [boxDetails, setBoxDetails] = useState([]);
    const [boxId, setBoxId] = useState("");
    const [boxData, setBoxData] = useState([]);
    const [pharmacyDetails, setPharmacyDetails] = useState("");
    const [parentaccountId, setParentAccountId] = useState("");
    const [accountDetails, setAccountDetails] = useState("");
    const [binId, setBinId] = useState('');
    const [stockCode, setStockCode] = useState('');
    const [pickUpCode, setPickUpCode] = useState('');
    const [printModal, setPrintModal] = useState(false);
    const [view, setView] = useState(false);
    const [orderStatus, setOrderStatus] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [errors, setErrors] = useState({});
    const [gridApi, setGridApi] = useState(null);
    const [isValue, setIsValue] = useState('');
    const [isView, setIsView] = useState(false)
    const [isAssign, setIsAssign] = useState(false)
    const [isUnAssign, setIsUnAssign] = useState(false)
    const [isRemind, setIsRemind] = useState(false)
    const [isHold, setIsHold] = useState(false)
    const [btnUpdateLoader, setBtnUpdateLoader] = useState(false);
    const [typeId, setTypeId] = useState('');
    const [isOpenBin, setIsOpenBin] = useState(false);
    const [authPassword, setIsAuthPassword] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [authLoader, setAuthLoader] = useState(false);
    const [socket, setSocket] = useState(null);
    const [requestNo, setRequestNo] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [products, setProducts] = useState([{}]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productOpt, setProductOpt] = useState('');
    let currentSocket;

    const initSocket = async () => {
        const user = JSON.parse(sessionStorage.getItem('user'))

        if (!currentSocket && user) {
            currentSocket = io(process.env.REACT_APP_VIDEO_SOCKET_BASEURL, {
                transports: ['websocket'],
                auth: {
                    token: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
                    isPortal: true,
                    userId: user.id || user.username,
                },
                secure: true,
                reconnectionAttempts: 5,
                closeOnBeforeunload: true,
            });
            /**
             ** Internal Socket Event **
             */
            if (currentSocket) {

                currentSocket.on('connect', () => {
                    console.log('Socket connection established from PORTAL(ReConnect)');
                });

                currentSocket.on('connect_error', (error) => {
                    console.log('Connect Error: ', error);
                    if (
                        error.message === 'TokenExpiredError' ||
                        error.message === 'JsonWebTokenError' ||
                        error.message === 'NoTokenFound'
                    ) {
                        console.log('Token issue!');
                    }
                });

                currentSocket.on('connect_failed', (error) => {
                    console.log('Connect Failed: ', error);
                });

                currentSocket.on('error', (error) => {
                    console.log('Error: ', error);
                });

                currentSocket.on('disconnect', (reason) => {
                    console.log(
                        `Socket Disconnected From Portal and Reason is: ${reason}`
                    );
                });

                currentSocket.on('openBinSuccess', (payload) => {
                    console.log(payload);
                    toast.success(
                        `${this.props.t('Toast.OpenBin')} ${payload.bin} ${this.props.t(
                            'Toast.Success'
                        )}`
                    );
                });
            }
            setSocket(currentSocket)
        }
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const TableHeader = [
        {
            headerName: "Request No",
            field: "request_no",
        },
        {
            headerName: "FirstName",
            field: "first_name",
        },
        {
            headerName: "LastName",
            field: "last_name",
        },
        {
            headerName: "Email",
            field: "email",
        },
        {
            headerName: "Product",
            field: "name",
        },
        {
            headerName: "Locker Doors",
            field: "bin_id",
        },
    ];

    const getPrescriptionDetails = async (BinId) => {
        const bin_info = {
            id: boxId,
            bin_id: BinId,
        };
        setLoader(true);
        if (isValue) {
            toast.error('Door not available !!')
        } else {
            const resData = await request("post", "apis/i-ecom/handleorders/order/rxbybin", bin_info);
            if (resData.message) {
                toast.error(resData.message);
                return;
            }
            if (resData.length > 0) {
                setCreateInvoice(true)
                setRequestNo(resData[0].request_no)
                setFirstName(resData[0].first_name)
                setLastName(resData[0].last_name)
                setView(resData[0].type_id === BOX_STATUS.ASSIGNED || BOX_STATUS.STOCKED ? true : false)
                setOrderStatus(resData[0].type_id)
                setEmail(resData[0].email)
                setBinId(resData[0].bin_id)
                setPickUpCode(resData[0].pickup_code)
                setStockCode(resData[0].stock_code)
                setOrderId(resData.map(item => item.id))
                setTypeId(resData[0].type_id)
                let codes = {
                    stockCode: resData[0].stock_code,
                    pickupCode: resData[0].pickup_code,
                };
                localStorage.setItem("label-codes", JSON.stringify(codes));
                setProducts(resData);
                const processedData = resData.map(item => ({
                    id: item.productId,
                    label: `${item.product_name} - ${item.product_id}`,
                    desc: item.desc
                }));
                setSelectedProducts(processedData);
            }
        }
        setLoader(false);
    };

    const getSelectedBin = (binId, position) => {
        setSelectedBinId(binId);
        setBoxPosition([undefined, 'undefined', null, 'null', ''].includes(position) ? 0 : position);
        setBinId(binId)
    };

    const getAssignedBins = async (box_id) => {
        setLoader(true);
        let _binStatus = [];
        if (box_id) {
            const binRes = await request("get", `binsbybox/${box_id}`);
            if (binRes.message) {
                toast.error(binRes.message);
                setLoader(false);
                return;
            }
            let deactivatedBins;
            _binStatus = binRes;
            if (_binStatus && _binStatus[0] && _binStatus[0].disabled_bins) {
                deactivatedBins = _binStatus[0].disabled_bins.toString().split(",");
                setDeactivatedBins(deactivatedBins);
            } else {
                setDeactivatedBins([]);
            }
            setBinStatus(_binStatus);
        }
        setLoader(false);
    };

    const getAccountDetails = async (accountId) => {
        setLoader(true);
        const accRes = await request("get", `account/${accountId}`);
        if (accRes.message) {
            toast.error(accRes.message);
            setLoader(false);
            return;
        }
        if (accRes && accRes.length > 0) {
            setAccountDetails(accRes[0]);
        }
    };

    const getPharmacyDetails = async (_boxId) => {
        setLoader(true);
        const detailRes = await request("get", `boxes/${_boxId}`);
        if (detailRes.message) {
            toast.error(detailRes.message);
            setLoader(false);
            return;
        }
        if (detailRes && detailRes.length > 0) {
            setPharmacyDetails(detailRes[0]);
            setParentAccountId(detailRes[0].parent_account_id);
            getAccountDetails(detailRes[0].parent_account_id);
        }
        setLoader(false);
    };

    const getExpansionBins = async (boxId, resBox) => {
        setLoader(true);
        const expansionsRes = await request("get", `box-expansions/${boxId}`);
        if (expansionsRes.message) {
            toast.error(expansionsRes.message);
            setLoader(false);
            return;
        }
        let order = 0;
        let UnitLayout = [];
        let UnitIndex = 0;

        if (expansionsRes && expansionsRes.length > 0) {
            if (expansionsRes.findIndex((x) => x.direction === 1) > -1) {
                expansionsRes.map((unit, index) => {
                    let boxlist = [];
                    if (unit.direction === 1) {
                        boxlist = unit;
                        boxlist.order = order + 1;
                        boxlist.selectedUnitIndex = Math.abs(unit.box_position);
                        boxlist.pos = "L";
                        boxlist.box_position = unit.box_position;
                        order = order + 1;
                        UnitLayout.push(boxlist);
                        UnitIndex = UnitIndex + 1;
                    }
                    return true;
                });
            }
            if (UnitLayout.length > 0) {
                UnitLayout.reverse();
            }
            order = order + 1;
            UnitIndex = UnitIndex + 1;
            const boxesExpansions = expansionsRes.find((x) => x.box.id === boxId);
            if (boxesExpansions && boxesExpansions !== undefined) {
                if (
                    boxesExpansions &&
                    boxesExpansions.box &&
                    boxesExpansions.box !== "" &&
                    boxesExpansions.box !== null &&
                    boxesExpansions.box !== undefined &&
                    boxesExpansions.box.type_id !== undefined &&
                    boxesExpansions.box.type_id === BOX_TYPE.Locker
                ) {
                    UnitLayout.push({
                        id: -1,
                        order: order,
                        pos: "M",
                        selectedUnitIndex: 0,
                        box_position: 0,
                    });
                } else if (
                    boxesExpansions &&
                    boxesExpansions.box &&
                    boxesExpansions.box !== "" &&
                    boxesExpansions.box !== null &&
                    boxesExpansions.box !== undefined &&
                    boxesExpansions.box.type_id === BOX_TYPE.M22
                ) {
                    UnitLayout.push({
                        id: -1,
                        order: order,
                        pos: "M22",
                        selectedUnitIndex: 0,
                        box_position: 0,
                    });
                } else if (
                    boxesExpansions &&
                    boxesExpansions.box &&
                    boxesExpansions.box !== "" &&
                    boxesExpansions.box !== null &&
                    boxesExpansions.box !== undefined &&
                    boxesExpansions.box.type_id === BOX_TYPE.M24
                ) {
                    UnitLayout.push({
                        id: -1,
                        order: order,
                        pos: "M24",
                        selectedUnitIndex: 0,
                        box_position: 0,
                    });
                } else {
                    UnitLayout.push({
                        id: -1,
                        order: order,
                        pos: "M",
                        selectedUnitIndex: 0,
                        box_position: 0,
                    });
                }
            }
            if (expansionsRes.findIndex((x) => x.direction === 2) > -1) {
                expansionsRes &&
                    expansionsRes.map((unit, index) => {
                        let boxlist = [];
                        if (unit.direction === 2) {
                            boxlist = unit;
                            boxlist.order = order + 1;
                            boxlist.selectedUnitIndex = unit.box_position;
                            boxlist.pos = "R";
                            boxlist.box_position = unit.box_position;
                            order = order + 1;
                            UnitLayout.push(boxlist);
                            UnitIndex = UnitIndex + 1;
                        }
                        return true;
                    });
            }
        } else {
            order = order + 1;

            const boxes = resBox && resBox.length && resBox.length > 0 && resBox.find((x) => x.id === boxId);
            if (boxes && boxes !== undefined) {
                if (
                    boxes &&
                    boxes.type_id !== "" &&
                    boxes.type_id !== null &&
                    boxes.type_id !== undefined &&
                    boxes.type_id === BOX_TYPE.Locker
                ) {
                    UnitLayout.push({
                        id: -1,
                        order: order,
                        pos: "M",
                        selectedUnitIndex: 0,
                        box_position: 0,
                    });
                } else if (
                    boxes &&
                    boxes.type &&
                    boxes.type_id !== "" &&
                    boxes.type_id !== null &&
                    boxes.type_id !== undefined &&
                    boxes.type_id === BOX_TYPE.M22
                ) {
                    UnitLayout.push({
                        id: -1,
                        order: order,
                        pos: "M22",
                        selectedUnitIndex: 0,
                        box_position: 0,
                    });
                } else if (
                    boxes &&
                    boxes.type &&
                    boxes.type_id !== "" &&
                    boxes.type_id !== null &&
                    boxes.type_id !== undefined &&
                    boxes.type_id === BOX_TYPE.M24
                ) {
                    UnitLayout.push({
                        id: -1,
                        order: order,
                        pos: "M24",
                        selectedUnitIndex: 0,
                        box_position: 0,
                    });
                } else {
                    UnitLayout.push({
                        id: -1,
                        order: order,
                        pos: "M",
                        selectedUnitIndex: 0,
                        box_position: 0,
                    });
                }
            }
        }
        setExpansionUnits(UnitLayout);
        setLoader(false);
    };

    const getBoxes = async (value) => {
        setLoader(true);
        const resBox = await request("get", "boxes");
        if (resBox.message) {
            setLoader(false)
            toast.error(resBox.message);
            return;
        }
        if (resBox && resBox.length > 0) {
            setBoxData(resBox);
            const boxTypes = resBox.map((type) => {
                return {
                    id: type.id,
                    label: type.name,
                    accountId: type.parent_account_id,
                };
            });

            if (boxTypes && boxTypes.length > 0 && value === 0) {
                const defaultSelectedBoxId = boxTypes[0].id;
                if (boxId === null) {
                    setBoxId(boxTypes[0].id);
                }
                getAssignedBins(defaultSelectedBoxId);
                getPharmacyDetails(defaultSelectedBoxId);
                getExpansionBins(defaultSelectedBoxId, resBox);

            } else {
                getAssignedBins(boxId);
                getPharmacyDetails(boxId);
                getExpansionBins(boxId, resBox);
            }
        }
        setLoader(false);
    };

    const getAllBoxes = async (id) => {
        const resBoxes = await request("get", `getBoxesById/${id}`);
        if (resBoxes.message) {
            toast.error(resBoxes.message);
            return;
        }
        if (resBoxes && resBoxes.length > 0) {
            let tmp = resBoxes.map((val) => {
                return {
                    id: val.id,
                    value: val.name,
                    brandName: val.brandName,
                    brandPhone: val.brandPhone,
                    ...val,
                };
            });
            setBoxDetails(tmp);
            setBoxTypeId(resBoxes[0].type_id);
            setBoxId([undefined, "undefined", null, "null", ""].includes(localStorage.getItem("BoxId")) ? resBoxes[0].id
                : localStorage.getItem("BoxId")
            );
            getBoxes(0);
        }
    };

    const getLocationTypes = async (_locationTypeId, toBeCalled, type) => {
        const resLocation = await request("get", "locationtypes");
        if (resLocation.message) {
            toast.error(resLocation.message);
            return;
        }
        if (resLocation && resLocation.length > 0) {
            const locationtypesdata = [];
            let newLocationtypes = [
                ...new Set(resLocation.map((obj) => obj.name)),
            ].map((name) => {
                return resLocation.find((obj) => obj.name === name);
            });
            for (let i = 0; i < newLocationtypes.length; i++) {
                if (
                    newLocationtypes[i].id !== "5e94cb81-ab9b-4e82-8b98-721a2d1794df" &&
                    newLocationtypes[i].id !== "fd7c6330-8b9a-45e2-a1f6-f791db65f5d0" &&
                    newLocationtypes[i].id !== "ee98b36c-a0e9-4cc4-a411-6c925ad442b6"
                ) {
                    let tmp = {};
                    tmp = {
                        id: newLocationtypes[i].id,
                        label: newLocationtypes[i].name_es,
                        ...newLocationtypes[i],
                    };
                    locationtypesdata.push(tmp);
                }
            }
            if (toBeCalled) {
                if (!locationTypeId) {
                    if (!_locationTypeId) {
                        getAllBoxes(locationtypesdata && locationtypesdata[0] && locationtypesdata[0].id);
                        setLocationTypeId(locationtypesdata && locationtypesdata[0] && locationtypesdata[0].id);
                    } else {
                        getAllBoxes(_locationTypeId);
                        setLocationTypeId(_locationTypeId);
                    }
                } else {
                    if (!_locationTypeId) {
                        getAllBoxes(locationtypesdata && locationtypesdata[0] && locationtypesdata[0].id);
                    } else {
                        getAllBoxes(_locationTypeId);
                    }
                }
            }
        }
    };

    const checkIfCodesExists = async (codes) => {
        setLoader(true);
        const resCode = await request('post', 'verify/codes', codes)
        // if (resCode.message) {
        //     toast.error(resCode.message)
        //     setLoader(false)
        //     return;
        // }
        if (resCode && resCode.isExist === true) {
            const _stockCode = randomized("0", 4);
            const _pickupCode = randomized("0", 4);
            let codes = { stockCode: _stockCode, pickupCode: _pickupCode };
            localStorage.setItem("label-codes", JSON.stringify(codes));
            setStockCode(_stockCode)
            setPickUpCode(_pickupCode)
        } else {
            let _codes = { stockCode: codes.stockCode, pickupCode: codes.pickupCode, };
            localStorage.setItem("label-codes", JSON.stringify(_codes));
            setStockCode(codes.stockCode)
            setPickUpCode(codes.pickupCode)
        }
        setLoader(false);
        return codes;
    };

    const generateCodes = () => {
        const _stockCode = randomized("0", 4);
        const _pickupCode = randomized("0", 4);
        checkIfCodesExists({ stockCode: _stockCode, pickupCode: _pickupCode, });
    }

    const clearFields = () => {
        setRequestNo('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setBinId('');
        setStockCode('');
        setPickUpCode('');
        setView(false);
        setOrderStatus('');
        setProducts([{}]);
        setSelectedProducts([]);
        setIsValue(false);
    }

    const handleInvoiceCreate = () => {
        setCreateInvoice(true);
        clearFields();
        setErrors({})
    };

    const handleBoxChange = async (event) => {
        const boxtype = boxData && boxData.length && boxData.length > 0 && boxData.find((x) => x.id === event.target.value);
        setBoxTypeId(boxtype && boxtype !== null && boxtype !== undefined && boxtype.type_id);
        setBoxId(event.target.value);
        localStorage.setItem("BoxId", event.target.value);
        if (gridApi) {
            gridApi.setDatasource(createDataSource(event.target.value));
        }
        getPharmacyDetails(event.target.value);
        getExpansionBins(event.target.value, boxData);
        getAssignedBins(event.target.value);
        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshInfiniteCache()
        }
    };


    const handleInvoiceClose = () => {
        setCreateInvoice(false);
    };


    const handleEventLog = async (event_params) => {
        const eventRes = await request('post', 'apis/i-ecom/status/events', event_params)
        if (eventRes.message) {
            toast.error(eventRes.message)
            return;
        }
    };

    const handleRemind = async () => {
        setBtnLoader(true)
        const remindObj = {
            pucode: pickUpCode,
            boxId: boxId,
            orderId: orderId,
        }
        const resRemind = await request('post', 'apis/i-ecom/status/smsReminder', remindObj)

        if (resRemind.message) {
            toast.error(resRemind.message)
            setBtnLoader(false)
            return
        }
        toast.success("Reminder sent successfully");
        setBtnLoader(false)
    }

    const handleRemove = async () => {
        setBtnLoader(true)
        const updateStatusOfObj = {
            event_type_name: BOX_STATUS.REMOVE_RX,
            type_id: BOX_STATUS.REMOVE_RX,
            box_id: boxId,
            order_id: orderId,
            parentAccountId: parentaccountId
        };

        const user = JSON.parse(sessionStorage.getItem("user"));
        const user_id = user.username;
        let descriptions = "Order is removed with stock code " + stockCode + "and pick up code " + pickUpCode;

        const event_params = {
            event_name: CurdTypes.UPDATE,
            event_type_name: EventTypes.REMOVE_RX,
            description: descriptions,
            type_id: BOX_STATUS.REMOVE_RX,
            parent_account_id: parentaccountId,
            user_id: user_id,
            order_id: orderId,
            box_id: boxId,
        };

        const resRemove = await request('put', 'apis/i-ecom/status', updateStatusOfObj)
        if (resRemove.message) {
            toast.error(resRemove.message);
            setBtnLoader(false);
            return
        }

        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshInfiniteCache()
        }
        toast.success("Order removed successfully");
        handleEventLog(event_params);
        handleInvoiceCreate();
        getBoxes();
        setBtnLoader(false);
    };

    const handleValidation = () => {
        const newErrors = {};

        if (!requestNo) {
            newErrors.requestNo = "Request No is required";
        }
        if (!email) {
            newErrors.email = "Email is required";
        }
        if (!firstName) {
            newErrors.firstName = "First name is required";
        }
        if (!lastName) {
            newErrors.lastName = "Last name is required";
        }
        if (!binId) {
            newErrors.binId = 'Bin is required'
        }
        if (selectedProducts.length <= 0) {
            toast.error("No product selected");
            newErrors.item = 'error'
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (type) => {
        if (!view) {
            if (handleValidation()) {
                const transformedData = selectedProducts.map(item => ({
                    id: item.id,
                    item: item.label,
                    desc: item.desc
                }));
                let orderdata = {};
                orderdata["request_no"] = requestNo;
                orderdata["first_name"] = firstName;
                orderdata["last_name"] = lastName;
                orderdata["email"] = email;
                orderdata["bin_id"] = binId;
                orderdata["pickup_code"] = pickUpCode;
                orderdata["stock_code"] = stockCode;
                orderdata["box_id"] = boxId;
                orderdata["type_id"] = BOX_STATUS.ASSIGNED;
                orderdata["items"] = transformedData;
                orderdata["box_position"] = boxPosition

                setLoader(true)
                if (orderdata !== null) {
                    const resOrder = await request('post', 'apis/i-ecom/handleorders', orderdata)

                    if (resOrder.message) {
                        setLoader(false)
                        toast.error("Something went wrong.");
                        return
                    }
                    toast.success("Order is created Successfully.", {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    const orderIds = resOrder && resOrder.map((item) => item.id)
                    const user = JSON.parse(sessionStorage.getItem("user"));
                    const user_id = user.username;
                    let descriptions = "Order is Assigned with stock code " + stockCode + "and pick up code " + pickUpCode;

                    const event_params = {
                        event_name: CurdTypes.INSERT,
                        event_type_name: EventTypes.ASSIGNED,
                        description: descriptions,
                        type_id: BOX_STATUS.ASSIGNED,
                        parent_account_id: parentaccountId,
                        user_id: user_id,
                        order_id: orderIds,
                        box_id: boxId,
                    };

                    if (gridRef && gridRef.current && gridRef.current.api) {
                        gridRef.current.api.refreshInfiniteCache()
                    }
                    if (type === 0) {
                        setPrintModal(true)
                        setTimeout(() => {
                            handlePrint()
                            handleInvoiceCreate();
                            setPrintModal(false)
                        }, 1500);
                    } else {
                        handleInvoiceCreate();
                        setPrintModal(false)
                    }
                    handleEventLog(event_params);
                    getBoxes();
                }
                setLoader(false);
            }
        } else {
            setPrintModal(true)
            setTimeout(() => {
                handlePrint()
                setPrintModal(false)
            }, 1500);
        }
    }

    const gridOptions = {
        rowModelType: 'infinite',
        gridBodyCls: 'ag-layout-auto-height',
    };

    const onGridReady = params => {
        setGridApi(params.api);
        params.api.setDatasource(createDataSource(boxId));
    };

    const createDataSource = (id) => {
        return {
            getRows: async (params) => {
                const { startRow, endRow, sortModel, filterModel, successCallback, failCallback } = params

                if (sortModel.length > 0) {
                    TransactionFilterObj.tableName = TransactionSortObj[sortModel[0].colId].tableName
                    TransactionFilterObj.sortColumn = TransactionSortObj[sortModel[0].colId].sortColumn
                    TransactionFilterObj.sortOrder = sortModel[0].sort;
                }

                if (filterModel && Object.keys(filterModel).length > 0) {
                    TransactionFilterObj.searchObject = filterModel;
                } else {
                    TransactionFilterObj.searchObject = ''
                }

                TransactionFilterObj.page = startRow;
                TransactionFilterObj.count = endRow;

                await request('post', `apis/i-ecom/handleorders/${id}`, TransactionFilterObj)
                    .then(response => {
                        if (response && response.results) {
                            const lastRow = response.results.length < (endRow - startRow) ? response.total : -1;
                            successCallback(response.results, lastRow);
                        } else {
                            successCallback([], 0);
                        }
                    })
                    .catch(error => {
                        console.error('API error:', error);
                        failCallback();
                    });
            }
        };
    };

    const handleRefresh = () => {
        if (gridApi) {
            gridApi.setDatasource(createDataSource(boxId));
        }
        setTimeout(() => {
            getBoxes();
        }, 500);
        handleInvoiceCreate()
    }

    const onSelectionChanged = () => {
        const [selectedRows] = gridRef.current.api.getSelectedRows()
        setIsValue(false);
        if (selectedRows && selectedRows.bin_id) {
            setTimeout(() => {
                getPrescriptionDetails(selectedRows.bin_id)
            }, 500);
        }
    };

    const handleRelease = async () => {
        setBtnLoader(true);
        const releaseObj = { orderId: orderId, boxId: boxId }
        const releaseRes = await request("post", "apis/i-ecom/status/releaseOrder", releaseObj)
        if (releaseRes.message) {
            toast.error(releaseRes.message)
            setBtnLoader(false)
            return
        }
        getBoxes();
        toast.success("Order release successfully.")
        setBtnLoader(false);
    }

    const handlePermissions = () => {
        const permissions = JSON.parse(sessionStorage.getItem("permissions"));

        if (!permissions || permissions.length === 0) {
            setIsView(false);
            setIsAssign(false);
            setIsUnAssign(false);
            setIsRemind(false);
            setIsHold(false);
            return;
        }

        const manageBoxes = permissions.filter(item => item.permission_entity_type === "Assign Prescription");

        if (manageBoxes.length === 0) {
            setIsView(false);
            setIsAssign(false);
            setIsUnAssign(false);
            setIsRemind(false);
            setIsHold(false);
            return;
        }

        setIsView(manageBoxes.some(item => item.name.trim() === 'View' && item.is_allowed === 1));
        setIsAssign(manageBoxes.some(item => item.name.trim() === 'Assign' && item.is_allowed === 1));
        setIsUnAssign(manageBoxes.some(item => item.name.trim() === 'UnAssign' && item.is_allowed === 1));
        setIsRemind(manageBoxes.some(item => item.name.trim() === 'Remind' && item.is_allowed === 1));
        setIsHold(manageBoxes.some(item => item.name.trim() === 'Hold/Resume' && item.is_allowed === 1));
    };

    const handleUpdate = async () => {
        if (handleValidation()) {
            let orderdata = {};
            const transformedData = selectedProducts.map(item => ({
                id: item.id,
                item: item.label,
                desc: item.desc
            }));
            orderdata["items"] = transformedData;
            orderdata["type_id"] = typeId;
            setBtnUpdateLoader(true)
            if (orderdata !== null) {
                const resOrder = await request('put', 'apis/i-ecom/handleorders', { data: orderdata, orderIds: orderId })

                if (resOrder.message) {
                    setBtnUpdateLoader(false)
                    toast.error("Error occur while updating.");
                    return
                }
                toast.success("Order is updated successfully.")
                if (gridRef && gridRef.current && gridRef.current.api) {
                    gridRef.current.api.refreshInfiniteCache()
                }
                getBoxes()
            }
            setBtnUpdateLoader(false);
        }
    }

    const handleValidate = () => {
        setAuthLoader(true)
        const userPayload = JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken.payload;
        const userName = userPayload.email
        Auth.signIn(userName, authPassword).then(async (user) => {
            if (user && user.attributes && user.attributes.email_verified) {
                setIsVerified(true)
                toast.success("Successfully validated.");
            }
            setAuthLoader(false)
            return
        }).catch(() => {
            toast.error("Enter valid password");
            setAuthLoader(false);
            return;
        })
    }

    const updateOderDetails = async () => {
        setAuthLoader(true);
        const updateStatusOfObj = {
            event_type_name: EventTypes.PICKED_UP,
            type_id: BOX_STATUS.PICKED_UP,
            box_id: boxId,
            order_id: orderId,
            pucode: pickUpCode,
            parentAccountId: parentaccountId,
        };

        const resOrder = await request('put', 'apis/i-ecom/status', updateStatusOfObj)

        if (resOrder.message) {
            setLoader(false)
            toast.error("Something went wrong.");
            return
        }
        const user = JSON.parse(sessionStorage.getItem("user"));
        const user_id = user.username;

        const event_params = {
            event_name: CurdTypes.INSERT,
            event_type_name: EventTypes.MANUAL_OVERRIDE,
            description: `Order is manual picked up with pick up code + ${pickUpCode}`,
            type_id: BOX_STATUS.PICKED_UP,
            parent_account_id: parentaccountId,
            user_id: user_id,
            order_id: orderId,
            box_id: boxId,
        };
        setAuthLoader(false)
        toast.success("Bin opened successfully");
        handleClose();
        handleEventLog(event_params);
        handleInvoiceCreate();
        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshInfiniteCache()
        }
        getBoxes();
    }

    const handleOpenBin = () => {
        if (isVerified) {
            const bin = binId;
            const box_id = pharmacyDetails.kiosk_box_id.toUpperCase();
            const command = 'OPEN-BIN';
            if (box_id.trim()) {
                const data = {
                    command,
                    bin,
                    box_id
                };
                if (socket && socket.emit) {
                    socket.emit('openBin', data)
                    updateOderDetails()
                }
            }
        } else {
            toast.error("User is not verified.")
        }
    }

    const handleClose = () => {
        setIsVerified(false);
        setIsOpenBin(false);
    }

    const getProductList = () => {
        let TransactionFilterObj = {
            searchtext: "",
            pageNo: 0,
            pageSize: 100,
            sortOrder: "desc",
            sortColumn: "created_on",
            tableName: "",
        };
        request('post', 'apis/i-ecom/ecom_product/getProduct', TransactionFilterObj)
            .then(response => {
                if (response && response.results) {
                    if (response.results.length > 0) {
                        const processedData = response.results.map(item => ({
                            id: item.id,
                            label: `${item.name} - ${item.product_id}`,
                            desc: item.desc
                        }));
                        setProductOpt(processedData)
                    } else {
                        setProductOpt([])
                    }
                } else if (response && response.message) {
                    toast.error('Error while fetching product');
                }
            })
            .catch(error => {
                console.error(error);
            })
    }


    useEffect(() => {
        clearFields();
        handlePermissions();
        setIsVerified(false);
        setIsOpenBin(false);
        getLocationTypes(undefined, true, "init");
        initSocket();
        getProductList();
    }, []);

    const handleAddProduct = () => {
        setProducts([...products, {}]);
    };

    const handleRemoveProduct = (index) => {
        const updatedProducts = [...products];
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);

        const updatedSelectedProducts = [...selectedProducts];
        updatedSelectedProducts.splice(index, 1);
        setSelectedProducts(updatedSelectedProducts);
    };

    const handleProductChange = (value, index) => {
        const updatedSelectedProducts = [...selectedProducts];
        updatedSelectedProducts[index] = value;
        setSelectedProducts(updatedSelectedProducts);
    };



    return (
        <div className="position-relative">
            {loader ? <div className="loader">
                <Loader />
            </div> : ""}
            <div className="row">
                <div className="col-8">
                    <div className="row">
                        <div className="col-8">
                            <Button
                                className="me-2 btn-sm"
                                variant="primary"
                                onClick={handleInvoiceCreate}
                            >
                                CREATE <i class="fa-solid fa-plus" />
                            </Button>
                        </div>
                        <div className="col-3">
                            <label>Location</label>
                            <Form.Select
                                value={boxId}
                                onChange={(e) => handleBoxChange(e)}
                            >
                                {boxDetails && boxDetails.map((type) => {
                                    return (
                                        <option className='fs-14' value={type.id}>{type.value}</option>
                                    )
                                })}
                            </Form.Select>
                        </div>
                        <div className="col-1">
                            <h3 className="mx-2 my-4" onClick={() => handleRefresh()}><i class="fa-solid fa-arrows-rotate" /></h3>
                        </div>
                    </div>
                    <div className="row vh-33 overflow-auto mt-4">
                        <div id="myGrid" className={"ag-theme-quartz"} style={{ height: "250px", boxSizing: "border-box", width: "100%" }}>
                            {boxId &&
                                <AgGridReact
                                    ref={gridRef}
                                    sideBar={false}
                                    suppressMenuHide={true}
                                    columnDefs={TableHeader}
                                    defaultColDef={{
                                        flex: 1,
                                        minWidth: 150,
                                        floatingFilter: true,
                                        filter: 'agTextColumnFilter',
                                        filterParams: {
                                            filterOptions: [{
                                                displayKey: 'searchtext',
                                                displayName: 'Search Text',
                                                predicate: (cellValue) => cellValue
                                            }],
                                            maxNumConditions: 1,
                                        },
                                        resizable: true,
                                        sortable: true,
                                        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                                    }}
                                    onGridReady={onGridReady}
                                    rowSelection={"single"}
                                    onSelectionChanged={onSelectionChanged}
                                    gridOptions={gridOptions}
                                />
                            }
                        </div>
                    </div>
                    <div className="mx-2">
                        {createInvoice ? (
                            <div className="row card h-auto mt-4 p-2">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-10"></div>
                                        <div className="col-2 text-end">
                                            <span
                                                className="fs-26 text-black"
                                                onClick={() => handleInvoiceClose()}
                                            >
                                                <i class="fa-solid fa-xmark" />
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="m-0"></hr>
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="pt-3">Request #</label>
                                            <input
                                                type="text"
                                                disabled={view}
                                                value={requestNo}
                                                onChange={(e) => setRequestNo(e.target.value)}
                                                onBlur={() => generateCodes()}
                                                className="form-control form-control-sm fs-14"
                                            />
                                            {errors.requestNo && <div className="text-danger fs-12">{errors.requestNo}</div>}
                                            <label className="pt-3">First Name</label>
                                            <input
                                                type="text"
                                                disabled={view}
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                className="form-control form-control-sm fs-14"
                                            />
                                            {errors.firstName && <div className="text-danger fs-12">{errors.firstName}</div>}
                                            <label className="pt-3">Last Name</label>
                                            <input
                                                type="text"
                                                disabled={view}
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                className="form-control form-control-sm fs-14"
                                            />
                                            {errors.lastName && <div className="text-danger fs-12">{errors.lastName}</div>}
                                            <label className="pt-3">Email</label>
                                            <input
                                                type="text"
                                                disabled={view}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="form-control form-control-sm fs-14"
                                            />
                                            {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                        </div>
                                        <div className="col-6 orderScroll">
                                            {products.map((_, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-12 d-flex">
                                                        <div className="mt-4">
                                                            {index > 0 ? (
                                                                <Button
                                                                    className="fs-24 btn-icon"
                                                                    variant="outlined"
                                                                    onClick={() => handleRemoveProduct(index)}
                                                                >
                                                                    <i className="fa-solid fa-circle-minus" />
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className="fs-24 btn-icon"
                                                                    variant="outlined"
                                                                    onClick={handleAddProduct}
                                                                >
                                                                    <i className="fa-solid fa-circle-plus" />
                                                                </Button>
                                                            )}
                                                        </div>
                                                        <div className="d-flex">
                                                            <div style={{ width: "170px" }}>
                                                                <label className="pt-3">Products</label>
                                                                <Select
                                                                    value={selectedProducts[index] || ''}
                                                                    onChange={(val) => handleProductChange(val, index)}
                                                                    options={productOpt}
                                                                    autoComplete="off"
                                                                    style={{
                                                                        lineHeight: '40px',
                                                                        color: '#7e7e7e',
                                                                        paddingLeft: '15px',
                                                                    }}
                                                                />
                                                            </div>
                                                            <div style={{ width: "250px", overflow: 'clip' }} className="ms-3 mt-4">
                                                                {selectedProducts[index] && (
                                                                    <p>{selectedProducts[index].desc}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-1">
                                            <label className="pt-3">Door</label>
                                            <div className="binBox">
                                                <span>{binId}</span>
                                            </div>
                                            {errors.binId && <div className="text-danger fs-12">{errors.binId}</div>}
                                        </div>
                                        <div className="col-2">
                                            <div className="d-flex flex-column">
                                                {orderStatus === BOX_STATUS.STOCKED && isRemind ?
                                                    <Button
                                                        variant="primary"
                                                        className="m-2 btn-sm"
                                                        onClick={() => handleRemind()}
                                                    >
                                                        {btnLoader ? <BtnLoader /> : "Remind"}
                                                    </Button> : ""}
                                                {orderStatus === BOX_STATUS.HOLD && isHold ?
                                                    <Button
                                                        variant="primary"
                                                        className="m-2 btn-sm"
                                                        onClick={() => handleRelease()}
                                                    >
                                                        {btnLoader ? <BtnLoader /> : "Release"}
                                                    </Button> : ""}
                                                <Button
                                                    variant="primary"
                                                    className="m-2 btn-sm"
                                                    onClick={() => handleInvoiceCreate()}
                                                >
                                                    Reset
                                                </Button>
                                                {isAssign ?
                                                    <Button
                                                        variant="primary"
                                                        className="m-2 btn-sm"
                                                        onClick={() => handleSubmit(0)}
                                                    >
                                                        {view ? 'Print' : 'Save & Print'}
                                                    </Button> : ""}
                                                {!view && isAssign ?
                                                    <Button
                                                        variant="primary"
                                                        className="m-2 btn-sm"
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        Save
                                                    </Button> : ""}
                                                {orderStatus === BOX_STATUS.ASSIGNED && isUnAssign ?
                                                    <Button
                                                        variant="primary"
                                                        className="m-2 btn-sm"
                                                        onClick={() => handleRemove()}
                                                    >
                                                        {btnLoader ? <BtnLoader /> : "Remove"}
                                                    </Button> : ""}
                                                {(orderStatus === BOX_STATUS.ASSIGNED || orderStatus === BOX_STATUS.STOCKED) && isUnAssign ?
                                                    <Button
                                                        variant="primary"
                                                        className="m-2 btn-sm"
                                                        onClick={() => handleUpdate()}
                                                    >
                                                        {btnUpdateLoader ? <BtnLoader /> : "Update"}
                                                    </Button> : ""}
                                                <Button
                                                    variant="primary"
                                                    className="m-2 btn-sm"
                                                    onClick={() => setIsOpenBin(true)}
                                                >
                                                    Open Bin
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>) : ""}
                    </div>
                </div>
                <div className="col-4">
                    <div className="d-flex justify-content-center">
                        <BoxLayout
                            BinStatus={binStatus}
                            deactivatedBins={deactivatedBins}
                            selectedBinId={selectedBinId}
                            expansionUnits={expansionUnits}
                            getPrescriptionDetails={getPrescriptionDetails}
                            getSelectedBin={getSelectedBin}
                            currentTabIndex={expansionUnits.findIndex(
                                (x) =>
                                    x.pos === "M" ||
                                    x.pos === "M22" ||
                                    x.pos === "FASTLANE" ||
                                    x.pos === "M24"
                            )}
                            boxTypeId={boxTypeId}
                            isViewPermissionAllowed={isView}
                        />
                        <div>
                            {Legend.map((val) => {
                                return (
                                    <div className="d-flex align-items-center">
                                        <div
                                            className="circle-legend"
                                            style={{ background: val.color }}
                                        />
                                        <h5>{val.label}</h5>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-6 py-1 box fs-20 fw-bold">
                            <span>Stock Code : {stockCode}</span>
                        </div>
                        <div className="col-6 py-1 box fs-20 fw-bold">
                            <span>Pick-Up Code : {pickUpCode}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="fade" show={printModal} centered>
                <Modal.Body>
                    <RetailLabelTemplate
                        boxId={boxId}
                        ref={componentRef}
                        accountDetails={accountDetails}
                        pharmacyDetails={pharmacyDetails}
                        boxDetails={boxDetails}
                        connectionType={0}
                        requestNo={requestNo}
                        productDetail={selectedProducts}
                    />
                </Modal.Body>
            </Modal>
            <Modal className="fade" show={isOpenBin} centered size="lg">
                <Modal.Header>
                    <Modal.Title>Manual OverRide</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-row justify-content-between">
                        <div>
                            <p className="fs-14 mb-2">
                                <span className="fw-bold">Request No: </span>{requestNo}
                            </p>
                            <p className="fs-14 mb-2">
                                <span className="fw-bold">Name: </span>{`${firstName} ${lastName}`}
                            </p>
                            <p className="fs-14 mb-2">
                                <span className="fw-bold">Email: </span>{email}
                            </p>
                            <p className="fs-14 mb-2">
                                <span className="fw-bold">Bin: </span>{binId}
                            </p>
                        </div>
                        <div className="px-5">
                            <table className="display w-100 dataTable">
                                <thead>
                                    <tr className="even">
                                        <th className="p-2">Product</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts && selectedProducts.map((element, index) => {
                                        return (
                                            <tr className="odd" key={index}>
                                                <td className="p-2">{element.label}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="my-2">
                        <p className="fs-18 fw-bold">Authorization</p>
                        <input
                            type="password"
                            name="password"
                            autoComplete="new-password"
                            value={authPassword}
                            onChange={(e) => setIsAuthPassword(e.target.value)}
                            className="form-control form-control-sm"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {authLoader ?
                        <Button
                            className="btn-sm"
                            variant="primary"
                        >
                            <BtnLoader />
                        </Button> :
                        <Button
                            className="btn-sm"
                            variant="primary"
                            onClick={() => isVerified ? handleOpenBin() : handleValidate()}
                        >
                            {isVerified ? "Open Bin" : "Validate"}
                        </Button>
                    }
                    <Button
                        className="btn-sm"
                        variant="danger light"
                        onClick={() => handleClose()}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AssignProduct;
